import React, { useState } from 'react';
import { Link } from "react-router-dom";
const Footer = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
});
const [isSubmitting, setIsSubmitting] = useState(false); // To manage the form submission state
const [error, setError] = useState(null); // To handle errors
const [successMessage, setSuccessMessage] = useState(''); // To show success message

const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
};

const divStyle2 = {
    clear: 'both',
    color: '#ff0000'
};
const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Disable the form while submitting
    setError(null); // Clear any previous error
    setSuccessMessage(''); // Clear any previous success message

    try {
        const response = await fetch('https://pcbconsumables.com/api/contact.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        });

        if (response.ok) {
            const result = await response.json();
            setSuccessMessage('Your message has been sent successfully!');
            setFormData({
                name: '',
                email: '',
                subject: '',
                message: '',
            }); // Clear form data
            console.log(result);
        } else {
            const errorResponse = await response.json();
            setError(errorResponse.message || 'An error occurred. Please try again.');
        }
    } catch (error) {
        setError('Failed to send the message. Please try again.');
    } finally {
        setIsSubmitting(false); // Re-enable the form
    }
};
  return (
    <footer className="site-footer">
      <div className="footer-container">
        <div className="container">
          <div className="row">
            {/* Our Contacts Section */}
            <div className="col-lg-4 col-sm-4 col-md-4 col-xs-12 footer footer-link">
              <aside className="widget widget_text">
                <div className="textwidget">
                  <h3>Our Contacts</h3>
                  <address>
                    PCB Consumables,<br />
                    #37/1, Nagasandra Main Road,<br />
                    Off. Tumkur Rd, <br />
                    Bangalore - 560 073, India <br />
                    Email: <a href="mailto:sales@pcbconsumables.com">sales@pcbconsumables.com</a>
                  </address>
                </div>
              </aside>
            </div>

            {/* Important Links Section */}
            <div className="col-lg-4 col-sm-4 col-md-4 col-xs-12 footer footer-link foot-sec">
              <h3>Important Links</h3>
              <aside className="widget widget_nav_menu">
                <div className="menu-footer-container">
                  <ul id="menu-footer" className="menu">
                    <li className="menu-item">
                          <Link to={`${process.env.PUBLIC_URL}`}>Home</Link>
                    </li>
                    <li className="menu-item">
                   
                      <Link to={`${process.env.PUBLIC_URL}/pcb-industry`}>PCB Industry</Link>
                    </li>
                    <li className="menu-item">
                        <Link to={`${process.env.PUBLIC_URL}/contract-manufacturing`}>Contract manufacturing</Link>
                    </li>
                    <li className="menu-item">
                     
                      <Link to={`${process.env.PUBLIC_URL}/touch-up-pens`}>PROMEX TOUCH UP PENS</Link>
                    </li>
                    <li className="menu-item">
                        <Link to={`${process.env.PUBLIC_URL}/contact`}>Contact</Link>
                    </li>
                  </ul>
                </div>
              </aside>
            </div>

            {/* Quick Enquiry Section */}
            <div className="col-lg-4 col-sm-4 col-md-4 col-xs-12 footer footer-social">
              <aside className="widget widget_text">
                <div className="textwidget">
                  <h3>Quick Enquiry</h3>
                  <form
                    onSubmit={handleSubmit}
                    
                    className="wpcf7-form"
                    noValidate
                  >
                    <div style={{ display: 'none' }}>
                      <input type="hidden" name="_wpcf7" value="18" />
                      <input type="hidden" name="_wpcf7_version" value="4.9" />
                      <input type="hidden" name="_wpcf7_locale" value="en_US" />
                      <input type="hidden" name="_wpcf7_unit_tag" value="wpcf7-f18-o1" />
                      <input type="hidden" name="_wpcf7_container_post" value="0" />
                    </div>

                    <div className="form-group">
                      <input
                        type="text"
                        name="name"
                        className="wpcf7-form-control"
                        placeholder="Your Name"
                        required
                        disabled={isSubmitting}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="form-group">
                      <input
                        type="email"
                        name="email"
                        className="wpcf7-form-control"
                        placeholder="Your Email"
                        required
                        disabled={isSubmitting}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="form-group">
                      <input
                        type="text"
                        name="message"
                        className="wpcf7-form-control"
                        placeholder="Quick Message"
                        required
                        disabled={isSubmitting}
                        onChange={handleChange}
                      />
                    </div>

                  
                    <div className="form-group">
                      <input
                        type="submit"
                        value="Submit"
                        disabled={isSubmitting}
                        className="wpcf7-submit btn btn-primary btn-lg btn-block"
                      />
                    </div>

                    <div className="wpcf7-response-output wpcf7-display-none">
                    {error && <p className="error-message" style={divStyle2}>{error}</p>}
                    {successMessage && <p className="success-message">{successMessage}</p>}
                    </div>
                  </form>
                </div>
              </aside>

              <div className="clear footer-border"></div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
