import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

const TouchUp = () => {
  return (
    <div id="page" className="hfeed site">
    <Header/>
 
 <script>
 $('.mainmenu ul li').addClass('dropdown');
 $('.mainmenu ul li ul').addClass('dropdown-menu');
 $('.mainmenu ul li ul.dropdown-menu > li').addClass('dropdown dropdown-submenu');
 $('.mainmenu ul li ul.dropdown-menu li ').removeClass('dropdown dropdown-submenu');
 </script>
      
    
        <div id="main" className="site-main">
    <div id="primary" className="content-area">
    <div className="container">

        <div className="main-content2 min_ht_400">
                        <div className="col-lg-12">
            <h1 className="page-subheading text_l">PROMEX TOUCH UP PENS</h1>
            <p className="main-txt">
                </p><p>Now you don’t have to throw away your finishes PCB’s for “opens”,  they can be re-worked very easily, reliably, quickly and cost effectively.</p>
            <p></p>
            </div>    
            
            <div className="text-content">
                <div className="col-lg-8">
                    <p>Promex makes it easy for you to electroplate safely and quickly with our new PEN PLATING SYSTEM. Using disposable pens, the PROMEX PLATING SYSTEM is perfect for OPTICAL FRAMES , JEWELRY, ORNAMENTS, ACCESSORIES, SMALL PARTS and any item that requires a new finish.</p>
                </div>
                <div className="col-lg-4">
                    <div className="right-prod">
                        <img alt="" src={`${process.env.PUBLIC_URL}/pcbconsumables/images/touch-up-pens.jpg`} className="img-responsive" />    </div>
                </div>
                                 
            </div>
            <div className="features-block">
                <div className="col-lg-12">
                    <div className="prod-features">
                        <h3 className="inner-page-subtitle">FEATURES</h3>
                        <ul className="featured-lists">
                            <li className="featured-list first-child"><strong>SIMPLE:</strong> The greatest feature of PROMEX is that any person without specialized technical skill can use it easily because its marking pen type catridge contains the plating solution.</li>
                            <li className="featured-list"><strong>PERFECT FINISH:</strong> The greatest feature of PROMEX is that any person without specialized technical skill can use it easily because its marking pen type catridge contains the plating solution.</li>
                            <li className="featured-list"><strong>PLATING AREA:</strong> All Pen contains 10ml. of solution which can plate 60cm x 60cm</li>
                            <ul>
                    </ul></ul></div>
                </div>
                    
                    
            </div>
            <div className="dimension">
                <div className="col-lg-4">
                    <h3 className="inner-page-subtitle">DIMENSION</h3>
                    <p>160mm X 140mm X 75mm</p>
                </div>
                <div className="col-lg-4">
                    <h3 className="inner-page-subtitle">GROSS WEIGHT</h3>
                    <p>300g</p>
                </div>
            </div>
            <div className="pen-tables">
                <div className="col-lg-6">
                    <div className="bs-table">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th colspan="2">OPTIONAL PLATING PEN</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>PROMEX<sup>R</sup>
                                    </td>
                                    <td>Copper plating pen</td>

                                </tr>
                                <tr>
                                    <td>PROMEX<sup>R</sup>
                                    </td>
                                    <td>Nickel plating pen</td>

                                </tr>
                                <tr>
                                    <td>PROMEX<sup>R</sup>
                                    </td>
                                    <td>Black Nickel plating pen</td>

                                </tr>
                                <tr>
                                    <td>PROMEX<sup>R</sup>
                                    </td>
                                    <td>Chrome color plating pen</td>

                                </tr>
                                <tr>
                                    <td>PROMEX<sup>R</sup>
                                    </td>
                                    <td>24k Gold Plating Pen</td>
                                </tr>
                                <tr>
                                    <td>PROMEX<sup>R</sup>
                                    </td>
                                    <td>14k Gold Plating Pen</td>
                                </tr>
                                <tr>
                                    <td>PROMEX<sup>R</sup>
                                    </td>
                                    <td>Pink Gold Plating Pen</td>
                                </tr>
                                <tr>
                                    <td>PROMEX<sup>R</sup>
                                    </td>
                                    <td>24k Heavy Gold Plating Pen</td>
                                </tr>
                                <tr>
                                    <td>PROMEX<sup>R</sup>
                                    </td>
                                    <td>18k Heavy Gold Plating Pen</td>
                                </tr>
                                <tr>
                                    <td>PROMEX<sup>R</sup>
                                    </td>
                                    <td>Heavy silver Plating Pen</td>
                                </tr>
                                <tr>
                                    <td>PROMEX<sup>R</sup>
                                    </td>
                                    <td>Rhodium Plating Pen</td>
                                </tr>
                                <tr>
                                    <td>PROMEX<sup>R</sup>
                                    </td>
                                    <td>Degreaser pen for stainless steel</td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="col-lg-6">

                    <div className="bs-table">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th colspan="2">PROMEX STANDARD SET</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>PROMEX<sup>R</sup>
                                    </td>
                                    <td>Pen plater rectifier with black &amp; red leads</td>

                                </tr>
                                <tr>
                                    <td>PROMEX<sup>R</sup>
                                    </td>
                                    <td>Degreaser pen</td>

                                </tr>
                                <tr>
                                    <td>PROMEX<sup>R</sup>
                                    </td>
                                    <td>18k Gold plating pen</td>

                                </tr>
                                <tr>
                                    <td>PROMEX<sup>R</sup>
                                    </td>
                                    <td>Silver Plating Pen</td>

                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>

            </div>
            <div className="operating-ins">
                <div className="col-lg-12">
                    <h2 className="inner-page-sub-head">OPERATING INSTRUCTIONS</h2>
                    <div className="bs-operating">
                        <h4>Using the PROMEX PLATING SYSTEM</h4>
                        <ol>
                            <li>Clean the area thoroughly by wiping with a cloth. Heavily soiled or rusted areas should be cleaned with an abrasive before proceeding. For best results items to be plated should be first cleaned with metal polish</li>
                            <li>Plug the plating unit into a standard 120V (220V Converted) outlet, connect the red cord to the red (+) terminal the black cord to the black(-) terminal. Turn on the Power switch. The Pilot lamp will illuminate.
                            </li>
                            <li><strong>DEGREASING PROCESS</strong>- In all plating, including our PROMEX system, it is essential to remove grease and oil from the surface of the object to be plated.

                                <ul>
                                    <li className="first-child">Plug the end of the degreasing pen into the socket at the end of the red(+) connecting cord and uncap the pen</li>
                                    <li>Clip the object at the end of the black(-) cord and hold it in a level position.</li>
                                    <li>Degreasing pen has a green colored strip. Turn pin selector switch to the green position to get the correct voltage.</li>
                                    <li className="last-child">Rub degreasing pen lightly over the surface of the object, small bubbles will form that will remove oil and grease. Check if degreas-ing is complete by rinsing the object in water. If the surface does not repel water degreasing is completed. (about 10 seconds are enough for an object the size of a coin). Rinse the surface well in water. Please do not dry or wipe the object to be plated after rinsing as wiping the surface will result in poorer plating.</li>
                                </ul>
                            </li>
                            <li><strong>METAL PLATING USING THE CODING SYSTEM</strong>
                                <br/>The PROMEX pen has a color coding system which correctly matches the desired plating pen with the corresponding voltage to be used. For example, when plating gold objects the gold plating pen has a blue colored strip along its base. This blue strip indicates the correct voltage that should be used. Simply adjust the corresponding position on the pen selector to insure that you are using the proper voltage.
                            </li>
                            <li><strong>RINSE AGAIN</strong>
                                <br/>Rinse the surface again after the metal plating and wipe with soft cloth.
                                <ul>
                                    <li className="first-child last-child">All PROMEX plating pens are designed to drip sufficient solution when holding the pens in a point-downward position. The solution will continue to drip when the pens are held with the point down. Operators should use the pens at an angle to control solution flow and prevent excessive drip.</li>
                                </ul>
                            </li>
                        </ol>

                        <h3 className="inner-page-subtitle">CAUTION:</h3>
                        <ol>
                            <li>Aluminum and Titanium cannot be plated.</li>
                            <li>Chromium cannot be plated over.</li>
                            <li>For stainless steel material, use the special degreasing pen, Degreaser for stainless steel, only.</li>
                            <li>Keep the pens in the upright position when not in use (tip up). 5. Keep out of reach of children.</li>
                        </ol>
                    </div>
                </div>
            </div>

        </div>
    </div>      
    </div>
    
            </div>
    
     <Footer/>
        </div>
  );
};

export default TouchUp;
