
import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

const Header= ()=> {
	const [activeLink, setActiveLink] = useState(null);
    // Function to handle the click event and set the active link
    useEffect(() => {
        // Extract the URL slug from the window's location path
        const currentSlug = window.location.pathname.split('/').pop(); // Get the last part of the URL
        console.log(currentSlug);
        if (currentSlug === '') {
            setActiveLink('home');
        }
		else if (currentSlug === 'pcb-industry' || currentSlug === 'contract-manufacturing' || currentSlug === 'touch-up-pens') {
            setActiveLink('products');
        }
        else {
            setActiveLink(currentSlug);
        }

    }, []); // Empty array means this effect runs only on component mount
    return (
        <div className="header-container">
        <div className="header-top-container">
            <div className="container">
                <div className="head-top-left col-lg-7 col-sm-7 col-md-7 col-xs-8">
                    <div className="toll-free-number">
                        <span className="call-icon"></span><span className="header-text">Call Us:+91 99720 15210</span>
                    </div>
                    <div className="top-mail-id">
                        <span className="mail-icon"></span>
                        <a href="mailto:sales@pcbconsumables.com" className="header-text">sales@pcbconsumables.com</a>
                    </div>
                </div>
                <div className="head-top-right col-lg-5 col-sm-5 col-md-5 col-xs-4">
                    <ul className="social-links clearfix">
                        <li>
                            <a className="fa fa-facebook" href="#"></a>
                        </li>
                        <li>
                            <a className="fa fa-twitter" href="#"></a>
                        </li>
                        <li>
                            <a className="fa fa-linkedin" href="#"></a>
                        </li>
                        <li>
                            <a className="fa fa-pinterest" href="#"></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="menu-container">
            <div className="container">
                <div className="menu-left col-lg-5 col-sm-4 col-md-6 col-xs-6">
				<Link  to={`${process.env.PUBLIC_URL}/`} aria-current="page">
				<img src="https://lifequoteshub.com/wp-content/uploads/2014/10/copy-logo.png" 
				width="402" height="50" alt=""/></Link>
					
                </div>
                <div className="mainmenu navbar menu-right col-lg-7 col-sm-8 col-md-6 col-xs-6" role="navigation">
					
						<div className="navbar-header">
							<button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#b-menu-1">
								<span className="sr-only">Toggle navigation</span>
								<span className="icon-bar"></span>
								<span className="icon-bar"></span>
								<span className="icon-bar"></span>
							</button>
							<a className="navbar-brand" href="#" id="menu" data-toggle="collapse" data-target="#b-menu-1">Menu</a>
						</div>
						<div className="collapse navbar-collapse" id="b-menu-1">
							<div className="menu-header-container"><ul id="menu-header" className="nav navbar-nav nav-menu"><li id="menu-item-6"  className={activeLink === 'home' ? 'menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item current_page_item' : 'menu-item menu-item-type-post_type menu-item-object-page menu-item-home  page_item'}>
							
								<Link  to={`${process.env.PUBLIC_URL}/`} aria-current="page">Home</Link>
								</li>
<li id="menu-item-201" className={activeLink === 'products' ? 'menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-201 page_item current_page_item' : 'menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-201 page_item'}><a>Products</a>
<ul className="sub-menu dropdown-menu">
	<li id="menu-item-111" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-111">
	<Link  to={`${process.env.PUBLIC_URL}/pcb-industry`} aria-current="page">PCB Industry</Link>
		
	<ul className="sub-menu dropdown-menu">
		<li id="menu-item-259" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-259"><a target="_blank" rel="noopener" href="https://www.ccp.com.tw/ccpweb.nsf/ProductEN?OpenAgent&amp;Product=COPPER%20FOIL">Copper Foil</a></li>
		<li id="menu-item-260" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-260"><a target="_blank" rel="noopener" href="http://www.ventec-group.com/">CCL</a></li>
		<li id="menu-item-261" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-261"><a target="_blank" rel="noopener" href="http://www.ventec-group.com/">IMS (Metal Clad)</a></li>
		<li id="menu-item-262" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-262"><a target="_blank" rel="noopener" href="http://www.thinflex.com.tw/">Flex CCL</a></li>
		<li id="menu-item-255" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-255"><a target="_blank" rel="noopener" href="http://cmcink.cn/index_en.html">Pism Ink</a></li>
		<li id="menu-item-256" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-256"><a href="#">PHOTO POLYMER DRY FILM</a></li>
		<li id="menu-item-257" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-257"><a target="_blank" rel="noopener" href="https://www.key-ware.com.tw/en/Products_detail/1/Drilling">Drills &#038; Routers</a></li>
		
	</ul>
</li>
	<li id="menu-item-109" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-109">
		
		<Link  to={`${process.env.PUBLIC_URL}/contract-manufacturing`} aria-current="page">Contract manufacturing</Link>
		</li>
	<li id="menu-item-110" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-110">
		
		<Link  to={`${process.env.PUBLIC_URL}/touch-up-pens`} aria-current="page">PROMEX TOUCH UP PENS</Link>
		</li>
	<li id="menu-item-319" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-319"><a href="#">Dupont Electronic Materials</a>
	<ul className="sub-menu dropdown-menu">
		<li id="menu-item-320" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-320"><a href="#">Hybrid Microcircuit Materials</a></li>
		<li id="menu-item-321" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-321"><a href="#">GreenTape – LTCC system</a>
		<ul className="sub-menu dropdown-menu">
			<li id="menu-item-322" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-322"><a href="https://www.dupont.com/products/green-tape-9k7-ltcc-material-system.html">GreenTape™ 9K7 LTCC System</a></li>
			<li id="menu-item-323" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-323"><a href="https://www.dupont.com/products/greentape-951-ltcc-material-system.html">GreenTape™ 951 LTCC System</a></li>
		</ul>
</li>
	</ul>
</li>
</ul>
</li>
<li id="menu-item-263" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-263"><a href="#">Drill Room</a>
<ul className="sub-menu dropdown-menu">
	<li id="menu-item-264" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-264"><a href="#">Machine</a>
	<ul className="sub-menu dropdown-menu">
		<li id="menu-item-265" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-265"><a href="#">Drilling</a></li>
		<li id="menu-item-266" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-266"><a target="_blank" href="https://www.sogotec.com.tw/">Routing</a></li>
	</ul>
</li>
	<li id="menu-item-267" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-267"><a href="#">Tools</a>
	<ul className="sub-menu dropdown-menu">
		<li id="menu-item-268" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-268"><a href="#">Drill Bits</a></li>
		<li id="menu-item-269" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-269"><a href="#">Routing Bits</a></li>
	</ul>
</li>
	<li id="menu-item-270" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-270"><a href="#">Entry</a>
	<ul className="sub-menu dropdown-menu">
		<li id="menu-item-271" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-271"><a target="_blank" rel="noopener" href="http://www.aluminium-entry-sheet.com/">Aluminum</a></li>
		<li id="menu-item-272" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-272"><a target="_blank" rel="noopener" href="https://pcbconsumables.com/uploads/2019/04/Lubricated-aluminum-introduction.pdf">Lubricated Aluminum</a></li>
		<li id="menu-item-273" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-273"><a target="_blank" rel="noopener" href="https://pcbconsumables.com/uploads/2019/04/0.5mm-PHP-data-sheet.pdf">Phenolic</a></li>
	</ul>
</li>
	<li id="menu-item-274" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-274"><a href="#">Back Up</a>
	<ul className="sub-menu dropdown-menu">
		<li id="menu-item-275" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-275"><a href="#">MDF</a></li>
		<li id="menu-item-276" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-276"><a target="_blank" rel="noopener" href="https://pcbconsumables.com/uploads/2019/04/HDF.pdf">HDF</a></li>
		<li id="menu-item-277" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-277"><a target="_blank" rel="noopener" href="https://pcbconsumables.com/uploads/2019/04/White-melamine-board1.pdf">Melamine</a></li>
		<li id="menu-item-278" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-278"><a href="#">Routing Back Plate</a></li>
	</ul>
</li>
</ul>
</li>
<li id="menu-item-279" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-279"><a href="#">Services</a>
<ul className="sub-menu dropdown-menu">
	<li id="menu-item-280" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-280"><a href="#">Re-sharpening</a></li>
	<li id="menu-item-282" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-282"><a href="#">Spindling Rebuilding</a>
	<ul className="sub-menu dropdown-menu">
		<li id="menu-item-283" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-283"><a href="#">Authorized Westwind Rebuilding Facility</a></li>
		<li id="menu-item-284" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-284"><a href="#">Authorized Mechatronics</a></li>
		<li id="menu-item-285" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-285"><a href="#">Authorized ABL</a></li>
	</ul>
</li>
</ul>
</li>
<li id="menu-item-28" className={activeLink === 'contact' ? 'menu-item menu-item-type-post_type menu-item-object-page menu-item-28 current_page_item' : 'menu-item menu-item-type-post_type menu-item-object-page menu-item-28'}>
	
	<Link  to={`${process.env.PUBLIC_URL}/contact`} aria-current="page">Contact</Link>
	</li>
</ul></div>						</div>

                </div>
            </div>
        </div>
    </div>
    );
};

export default Header;