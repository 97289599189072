import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

const Manufacturing = () => {
  return (
    <div id="page" className="hfeed site">
    <Header/>
 
 <script>
 $('.mainmenu ul li').addClass('dropdown');
 $('.mainmenu ul li ul').addClass('dropdown-menu');
 $('.mainmenu ul li ul.dropdown-menu > li').addClass('dropdown dropdown-submenu');
 $('.mainmenu ul li ul.dropdown-menu li ').removeClass('dropdown dropdown-submenu');
 </script>
      
    
        <div id="main" className="site-main">
    <div id="primary" className="content-area">
    <div className="container">
		<div className="main-content2 min_ht_400">
						<h1 className="page-subheading text_l">Contract manufacturing</h1>
			<p className="main-txt"></p><p>We supply electronic components, connectors, adhesives, conformal coating films, tapes and all types consumables. For Medical, Industrial and Automotive Verticals.</p>
<p></p>
						<div className="container">

        <div className="row">
            <div className="col-lg-2 col-md-4 col-xs-6 thumb">
                <a href="#" className="thumbnail">
                    <img  src={`${process.env.PUBLIC_URL}/pcbconsumables/images/cm-logo-1.jpg`} className="img-responsive" alt="" loading="lazy"/>
                        </a>
            </div>
            <div className="col-lg-2 col-md-4 col-xs-6 thumb">
                <a href="#" className="thumbnail">
                    <img alt="" src={`${process.env.PUBLIC_URL}/pcbconsumables/images/cm-logo-2.jpg`} className="img-responsive"/>
                </a>
            </div>
            
            
            <div className="col-lg-2 col-md-4 col-xs-6 thumb">
                <a href="#" className="thumbnail">
                    <img alt="" src={`${process.env.PUBLIC_URL}/pcbconsumables/images/cm-logo-6.jpg`} className="img-responsive"/>
                </a>
            </div>
            <div className="col-lg-2 col-md-4 col-xs-6 thumb">
                <a href="#" className="thumbnail">
                    <img alt="" src={`${process.env.PUBLIC_URL}/pcbconsumables/images/cm-logo-7.jpg`} className="img-responsive"/>
                </a>
            </div>
        </div>

        
        

    </div>

		</div>
	</div>      
    </div>
    
            </div>
    
     <Footer/>
        </div>
  );
};

export default Manufacturing;
