import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Banner from '../components/Banner';


const Home = () => {
    return (
        <div id="page" className="hfeed site">
       <Header/>
    
    <script>
    $('.mainmenu ul li').addClass('dropdown');
    $('.mainmenu ul li ul').addClass('dropdown-menu');
    $('.mainmenu ul li ul.dropdown-menu > li').addClass('dropdown dropdown-submenu');
    $('.mainmenu ul li ul.dropdown-menu li ').removeClass('dropdown dropdown-submenu');
    </script>
    
    
    
        <div id="main" className="site-main">
    
  <Banner/>
    
    <div className="main-container">
            <div className="container">
                <div className="main-content2">
                                <h1 className="page-subheading">Welcome to PCB Consumables</h1>
                    <p className="main-txt"><p>We are an authorized distributor for various manufacturers from USA, Europe and Taiwan. We have been in business since 1989. We have offices across India to cater to both PCB manufacturers and Electronic Contract Manufacturers (CEM).</p>
    </p>
    
                </div>
            </div>
        </div>
    <div className="clear"></div>
    <div className="container">
            <div className="thin-bottom-border"></div>
        </div>
    
    <div className="container products">
        <h2 className="page-subheading">OUR<span>PRODUCTS</span></h2>
        
    <div className="row">
                                            <div className="col-lg-3 col-sm-6 col-md-6 col-xs-12">
    <div className="common_cls">
                                <div className="prod-thumb">
                                    <img width="288" height="202" src={`${process.env.PUBLIC_URL}/uploads/2014/10/prod-23.jpg`} className="attachment-post-thumbnail size-post-thumbnail wp-post-image" alt="prod-2" loading="lazy" />							</div>
                                <div className="caption">
                                        <h4><a href="pcb-industry/index.html">PCB Industry</a></h4>
                                    <p>We represent Rigid and Flex Copper Clad Laminates, DFPR Dry film, Copper Foil, PISM Ink, Back-up boards for Drilling and Routing, multilayer Tooling related materials, PROMEX touch-up pens and many more consumables.</p>
                                </div>
    </div>
                            </div>						
                                                    <div className="col-lg-3 col-sm-6 col-md-6 col-xs-12">
    <div className="common_cls">
                                <div className="prod-thumb">
                                    <img width="288" height="202" src={`${process.env.PUBLIC_URL}/uploads/2014/10/prod-32.jpg`} className="attachment-post-thumbnail size-post-thumbnail wp-post-image" alt="prod-3" loading="lazy" />							</div>
                                <div className="caption">
                                        <h4><a href="contract-manufacturing/index.html">Contract manufacturing</a></h4>
                                    <p>We supply electronic components, connectors, adhesives, conformal coating films, tapes and all types consumables. For Medical, Industrial and Automotive Verticals.</p>
                                </div>
    </div>
                            </div>						
                                                    <div className="col-lg-3 col-sm-6 col-md-6 col-xs-12">
    <div className="common_cls">
                                <div className="prod-thumb">
                                    <img width="288" height="202" src={`${process.env.PUBLIC_URL}/uploads/2014/10/touch-up-pens-t.jpg`} className="attachment-post-thumbnail size-post-thumbnail wp-post-image" alt="touch-up-pens-t" loading="lazy" />							</div>
                                <div className="caption">
                                        <h4><a href="touch-up-pens/index.html">PROMEX TOUCH UP PENS</a></h4>
                                    <p>Now you don’t have to throw away your finishes PCB’s for “opens”,  they can be re-worked very easily, reliably, quickly and cost effectively.</p>
                                </div>
    </div>
                            </div>						
                                                    <div className="col-lg-3 col-sm-6 col-md-6 col-xs-12">
    <div className="common_cls">
                                <div className="prod-thumb">
                                    <img width="291" height="201" src={`${process.env.PUBLIC_URL}/uploads/2019/05/dupont_bg.png`} className="attachment-post-thumbnail size-post-thumbnail wp-post-image" alt="" loading="lazy" />							</div>
                                <div className="caption">
                                        <h4><a href="http://www.dupont.com/products-and-services/electronic-electrical-materials.html">Dupont</a></h4>
                                    <p>Innovative, high-performing thick film materials for flexible, rigid and hybrid substrates
    <h5>Products Families:</h5>
    <ul className="dupont_list">
         <li>Hybrid Circuit Materials</li>
         <li>Passive Component Materials</li>
         <li>Low-temp Co-fired Ceramic Materials</li>
         <li>Printed Electronic Materials</li>
    </ul></p>
                                </div>
    </div>
                            </div>						
                                                    </div>
                
    </div>
    
    <div className="clear"></div>
    <div className="services-block">
        <div className="container">
            <h2 className="page-subheading-white">
                <span className="line1"></span>
                <span className="line2"></span>About US</h2>
            <div className="row">
                    <div className="col-lg-3 col-sm-6 col-md-6 col-xs-12">
                    <div className="service-block">
                        <div className="service-icon">
                            <img width="47" height="49" src={`${process.env.PUBLIC_URL}/uploads/2014/10/services-icon-41.png`} className="attachment-post-thumbnail size-post-thumbnail wp-post-image" alt="" loading="lazy" />					</div>
                        <h3 className="service-heading">High Quality</h3>
                        <p>All the manufacturers we represent,  are Tier-1  manufacturers in their respective products.  All are well established and seasoned multinationals.  Everybody has ISO certification and additional certifications for Automotive, Mil Spec and Aerospace.  All manufacturers have presence in matured markets like USA, Taiwan, Germany,  and China.</p>
                        
                    </div>
                </div>
                            <div className="col-lg-3 col-sm-6 col-md-6 col-xs-12">
                    <div className="service-block">
                        <div className="service-icon">
                            <img width="47" height="49" src={`${process.env.PUBLIC_URL}/uploads/2014/10/services-icon-31.png`} className="attachment-post-thumbnail size-post-thumbnail wp-post-image" alt="" loading="lazy" />					</div>
                        <h3 className="service-heading">Competitiveness</h3>
                        <p>As we are promoting these products in India,  we understand the competitive dynamics Indian customers face in the Asia-pacific region. Cognizant of the Chinese competition,  our principals are allowing us to price the products in  such a way,  so India can compete with the big Elephant in the region.   In case of issues,  we can always go back to our principals for project specific pricing.</p>
                        
                    </div>
                </div>
                            <div className="col-lg-3 col-sm-6 col-md-6 col-xs-12">
                    <div className="service-block">
                        <div className="service-icon">
                            <img width="47" height="49" src={`${process.env.PUBLIC_URL}/uploads/2014/10/services-icon-22.png`} className="attachment-post-thumbnail size-post-thumbnail wp-post-image" alt="" loading="lazy" />					</div>
                        <h3 className="service-heading">Multilocational</h3>
                        <p>We have multiple locations across India.  Chennai, Bangalore, Pune and Delhi.  We hope to stock all our products locally, provided we have good visibility or projections.  And we hope the government regulations permit us to do that without incurring duties.</p>
                        
                    </div>
                </div>
                            <div className="col-lg-3 col-sm-6 col-md-6 col-xs-12">
                    <div className="service-block">
                        <div className="service-icon">
                            <img width="47" height="49" src={`${process.env.PUBLIC_URL}/uploads/2014/10/services-icon-11.png`} className="attachment-post-thumbnail size-post-thumbnail wp-post-image" alt="services-icon-1" loading="lazy" />					</div>
                        <h3 className="service-heading">Trained Sales Force</h3>
                        <p>All our team members are well trained across the full range of our offering.  They have been factory trained.  Of course occasionally you will stump us, through consultation and collaboration with the manufacturers we can definitely address all your concerns.</p>
                        
                    </div>
                </div>
                        </div>
        </div>
    </div>
    <div className="container clients">
        <div className="col-lg-12">
        <h2 className="page-subheading">PCB<span>Clients</span></h2>
        <div className="jcarousel-wrapper" data-scrollreveal="enter top and move 100px, wait 0.5s">
            <div className="jcarousel">
                <ul>
                                            <li>
                                <div className="prod-thumb">
                                    <span className="helper"></span>
                                    <img width="146" height="60" src={`${process.env.PUBLIC_URL}/uploads/2014/10/cl-14.jpg`} className="attachment-post-thumbnail size-post-thumbnail wp-post-image" alt="cl-14" loading="lazy" />							</div>
                            </li>
                                                    <li>
                                <div className="prod-thumb">
                                    <span className="helper"></span>
                                    <img width="146" height="60" src={`${process.env.PUBLIC_URL}/uploads/2014/10/cl-101.jpg`} className="attachment-post-thumbnail size-post-thumbnail wp-post-image" alt="cl-10" loading="lazy" />							</div>
                            </li>
                                                    <li>
                                <div className="prod-thumb">
                                    <span className="helper"></span>
                                    <img width="146" height="60" src={`${process.env.PUBLIC_URL}/uploads/2014/10/cl-4.jpg`} className="attachment-post-thumbnail size-post-thumbnail wp-post-image" alt="cl-4" loading="lazy" />							</div>
                            </li>
                                                    <li>
                                <div className="prod-thumb">
                                    <span className="helper"></span>
                                    <img width="146" height="60" src={`${process.env.PUBLIC_URL}/uploads/2014/10/cl-31.jpg`} className="attachment-post-thumbnail size-post-thumbnail wp-post-image" alt="cl-3" loading="lazy" />							</div>
                            </li>
                                                    <li>
                                <div className="prod-thumb">
                                    <span className="helper"></span>
                                    <img width="146" height="60" src={`${process.env.PUBLIC_URL}/uploads/2014/10/cl-13.jpg`} className="attachment-post-thumbnail size-post-thumbnail wp-post-image" alt="cl-1" loading="lazy" />							</div>
                            </li>
                                                    <li>
                                <div className="prod-thumb">
                                    <span className="helper"></span>
                                    <img width="146" height="60" src={`${process.env.PUBLIC_URL}/uploads/2014/10/cl-91.jpg`} className="attachment-post-thumbnail size-post-thumbnail wp-post-image" alt="cl-9" loading="lazy" />							</div>
                            </li>
                                                    <li>
                                <div className="prod-thumb">
                                    <span className="helper"></span>
                                    <img width="146" height="60" src={`${process.env.PUBLIC_URL}/uploads/2014/10/cl-81.jpg`} className="attachment-post-thumbnail size-post-thumbnail wp-post-image" alt="cl-8" loading="lazy" />							</div>
                            </li>
                                                    <li>
                                <div className="prod-thumb">
                                    <span className="helper"></span>
                                    <img width="146" height="60" src={`${process.env.PUBLIC_URL}/uploads/2014/10/cl-7.jpg`} className="attachment-post-thumbnail size-post-thumbnail wp-post-image" alt="" loading="lazy" />							</div>
                            </li>
                                                    <li>
                                <div className="prod-thumb">
                                    <span className="helper"></span>
                                    <img width="146" height="60" src={`${process.env.PUBLIC_URL}/uploads/2014/10/cl-6.jpg`} className="attachment-post-thumbnail size-post-thumbnail wp-post-image" alt="" loading="lazy" />							</div>
                            </li>
                                                    <li>
                                <div className="prod-thumb">
                                    <span className="helper"></span>
                                    <img width="146" height="60" src={`${process.env.PUBLIC_URL}/uploads/2014/10/cl-5.jpg`} className="attachment-post-thumbnail size-post-thumbnail wp-post-image" alt="" loading="lazy" />							</div>
                            </li>
                                        </ul>
                
            </div>
            <a href="#" className="jcarousel-control-prev">
                <img src={`${process.env.PUBLIC_URL}/pcbconsumables/images/cl-prev.png`} alt="Previous"/>
            </a>
            <a href="#" className="jcarousel-control-next">
                <img src={`${process.env.PUBLIC_URL}/pcbconsumables/images/cl-next.png`} alt="Next"/>
            </a>
        </div>
            </div>
    </div>
    <div className="container"><hr className="seperator-line"/></div>
    <div className="container clients">
        <div className="col-lg-12">
        <h2 className="page-subheading">CEM<span>Clients</span></h2>
        <div className="jcarousel-wrapper" data-scrollreveal="enter top and move 100px, wait 0.5s">
            <div className="jcarousel">
                <ul>
                                            <li>
                                <div className="prod-thumb">
                                    <span className="helper"></span>
                                    <img width="146" height="60" src={`${process.env.PUBLIC_URL}/uploads/2014/10/cl-23.jpg`} className="attachment-post-thumbnail size-post-thumbnail wp-post-image" alt="cl-23" loading="lazy" />							</div>
                            </li>
                                                    <li>
                                <div className="prod-thumb">
                                    <span className="helper"></span>
                                    <img width="146" height="60" src={`${process.env.PUBLIC_URL}/uploads/2014/10/cl-22.jpg`} className="attachment-post-thumbnail size-post-thumbnail wp-post-image" alt="cl-22" loading="lazy" />							</div>
                            </li>
                                                    <li>
                                <div className="prod-thumb">
                                    <span className="helper"></span>
                                    <img width="146" height="60" src={`${process.env.PUBLIC_URL}/uploads/2014/10/cl-21.jpg`} className="attachment-post-thumbnail size-post-thumbnail wp-post-image" alt="cl-21" loading="lazy" />							</div>
                            </li>
                                                    <li>
                                <div className="prod-thumb">
                                    <span className="helper"></span>
                                    <img width="146" height="60" src={`${process.env.PUBLIC_URL}/uploads/2014/10/cl-20.jpg`} className="attachment-post-thumbnail size-post-thumbnail wp-post-image" alt="cl-20" loading="lazy" />							</div>
                            </li>
                                                    <li>
                                <div className="prod-thumb">
                                    <span className="helper"></span>
                                    <img width="146" height="60" src={`${process.env.PUBLIC_URL}/uploads/2014/10/cl-19.jpg`} className="attachment-post-thumbnail size-post-thumbnail wp-post-image" alt="cl-19" loading="lazy" />							</div>
                            </li>
                                                    <li>
                                <div className="prod-thumb">
                                    <span className="helper"></span>
                                    <img width="146" height="60" src={`${process.env.PUBLIC_URL}/uploads/2014/10/cl-18.jpg`} className="attachment-post-thumbnail size-post-thumbnail wp-post-image" alt="cl-18" loading="lazy" />							</div>
                            </li>
                                                    <li>
                                <div className="prod-thumb">
                                    <span className="helper"></span>
                                    <img width="146" height="60" src={`${process.env.PUBLIC_URL}/uploads/2014/10/cl-17.jpg`} className="attachment-post-thumbnail size-post-thumbnail wp-post-image" alt="cl-17" loading="lazy" />							</div>
                            </li>
                                                    <li>
                                <div className="prod-thumb">
                                    <span className="helper"></span>
                                    <img width="146" height="60" src={`${process.env.PUBLIC_URL}/uploads/2014/10/cl-16.jpg`} className="attachment-post-thumbnail size-post-thumbnail wp-post-image" alt="cl-16" loading="lazy" />							</div>
                            </li>
                                                    <li>
                                <div className="prod-thumb">
                                    <span className="helper"></span>
                                    <img width="146" height="60" src={`${process.env.PUBLIC_URL}/uploads/2014/10/cl-151.jpg`} className="attachment-post-thumbnail size-post-thumbnail wp-post-image" alt="cl-15" loading="lazy" />							</div>
                            </li>
                                        </ul>
                
            </div>
            <a href="#" className="jcarousel-control-prev">
                <img src={`${process.env.PUBLIC_URL}/pcbconsumables/images/cl-prev.png`} alt="Previous"/>
            </a>
            <a href="#" className="jcarousel-control-next">
                <img src={`${process.env.PUBLIC_URL}/pcbconsumables/images/cl-next.png`} alt="Next"/>
            </a>
        </div>
    </div>
    </div>
    <div className="clear"></div>
    
            </div>
    
   <Footer/>
        </div>
    );
};

export default Home;
