import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import Industry from './pages/Industry';
import Manufacturing from './pages/Manufacturing';
import TouchUp from './pages/TouchUp';
import Contact from './pages/Contact';

function App() {
	

  return (
	<Router>
	<Routes>
	  <Route path="/" element={<Home/>} />
	  <Route path="/pcb-industry" element={<Industry/>} />
	  <Route path="/contract-manufacturing" element={<Manufacturing/>} />
	  <Route path="/touch-up-pens" element={<TouchUp/>} />
	  <Route path="/contact" element={<Contact/>} />
	</Routes>
  </Router>
  );
}

export default App;
