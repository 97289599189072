import React, { useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: '',
    });

    const [isSubmitting, setIsSubmitting] = useState(false); // To manage the form submission state
    const [error, setError] = useState(null); // To handle errors
    const [successMessage, setSuccessMessage] = useState(''); // To show success message

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true); // Disable the form while submitting
        setError(null); // Clear any previous error
        setSuccessMessage(''); // Clear any previous success message

        try {
            const response = await fetch('https://pcbconsumables.com/api/contact.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                const result = await response.json();
                setSuccessMessage('Your message has been sent successfully!');
                setFormData({
                    name: '',
                    email: '',
                    subject: '',
                    message: '',
                }); // Clear form data
                console.log(result);
            } else {
                const errorResponse = await response.json();
                setError(errorResponse.message || 'An error occurred. Please try again.');
            }
        } catch (error) {
            setError('Failed to send the message. Please try again.');
        } finally {
            setIsSubmitting(false); // Re-enable the form
        }
    };
  return (
    <div id="page" className="hfeed site">
    <Header/>
 
 <script>
 $('.mainmenu ul li').addClass('dropdown');
 $('.mainmenu ul li ul').addClass('dropdown-menu');
 $('.mainmenu ul li ul.dropdown-menu > li').addClass('dropdown dropdown-submenu');
 $('.mainmenu ul li ul.dropdown-menu li ').removeClass('dropdown dropdown-submenu');
 </script>
      
    
        <div id="main" className="site-main">
    <div id="primary" className="content-area">
    <div className="container">
      <div className="main-content2 min_ht_400">
        <h1 className="page-subheading text_l">Contact</h1>
        <p className="main-txt"></p>
        <div className="col-lg-6">
          <div role="form" className="wpcf7" id="wpcf7-f17-p26-o1" lang="en-US" dir="ltr">
            <div className="screen-reader-response"></div>
            <form
             onSubmit={handleSubmit}
              className="wpcf7-form"
              noValidate
            >
              <p>
                Your Name (required)<br />
                <span className="wpcf7-form-control-wrap your-name">
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    size="40"
                    className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                    aria-required="true"
                    aria-invalid="false"
                    placeholder="Your name"
                    disabled={isSubmitting}
                  />
                </span>
              </p>
              <p>
                Your Email (required)<br />
                <span className="wpcf7-form-control-wrap email">
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    size="40"
                    className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email"
                    aria-required="true"
                    aria-invalid="false"
                    disabled={isSubmitting}
                  />
                </span>
              </p>
              <p>
                Your Subject<br />
                <span className="wpcf7-form-control-wrap subject">
                  <input
                    type="text"
                    name="subject"
                    value={formData.subject}
                    onChange={handleChange}
                    size="40"
                    className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                    aria-required="true"
                    aria-invalid="false"
                    placeholder="Subject"
                    disabled={isSubmitting}
                  />
                </span>
              </p>
              <p>
                Your Message<br />
                <span className="wpcf7-form-control-wrap message">
                  <textarea
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    cols="40"
                    rows="10"
                    className="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required"
                    aria-required="true"
                    aria-invalid="false"
                    placeholder="Your message"
                    disabled={isSubmitting}
                  ></textarea>
                </span>
              </p>
              <p>
                <input type="submit" value="Send" className="wpcf7-form-control wpcf7-submit"  disabled={isSubmitting} />
              </p>
              {error && <p className="error-message">{error}</p>}
              {successMessage && <p className="success-message">{successMessage}</p>}
              <div className="wpcf7-response-output wpcf7-display-none"></div>
            </form>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="address-info">
            <h4>HEAD OFFICE</h4>
            <address>
              PCB Consumables,<br />
              #37/1, Nagasandra Main Road,<br />
              Off. Tumkur Rd,<br />
              Bangalore – 560 073, India<br />
              Email: <a href="mailto:sales@pcbconsumables.com" target="_blank" rel="noopener noreferrer">sales@pcbconsumables.com</a>
            </address>
          </div>
          <div className="google-map">
            <h4>FIND US HERE</h4>
            <p>
              <iframe
                loading="lazy"
                style={{ border: 0 }}
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.951424137312!2d77.50300450000002!3d13.038764!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae3cc5b7d937e7%3A0x3aa956eb95ce961e!2sNagasandra+Main+Rd%2C+Bengaluru%2C+Karnataka!5e0!3m2!1sen!2sin!4v1414411000031"
                width="600"
                height="263"
                title="myFrame"
                allowFullScreen
              ></iframe>
            </p>
          </div>
        </div>
      </div>
    </div>   
    </div>
    
            </div>
    
     <Footer/>
        </div>
  );
};

export default Contact;
