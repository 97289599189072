import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

const Industry = () => {
  return (
    <div id="page" className="hfeed site">
    <Header/>
 
 <script>
 $('.mainmenu ul li').addClass('dropdown');
 $('.mainmenu ul li ul').addClass('dropdown-menu');
 $('.mainmenu ul li ul.dropdown-menu > li').addClass('dropdown dropdown-submenu');
 $('.mainmenu ul li ul.dropdown-menu li ').removeClass('dropdown dropdown-submenu');
 </script>
      
    
        <div id="main" className="site-main">
    <div id="primary" className="content-area">
        <div className="container">
        <div className="pcb-industry">
        <h1 className="page-subheading text_l">PCB Industry</h1>
            <div id="content" className="site-content" role="main">
                            <p>We represent Rigid and Flex Copper Clad Laminates, DFPR Dry film, Copper Foil, PISM Ink, Back-up boards for Drilling and Routing, multilayer Tooling related materials, PROMEX touch-up pens and many more consumables.</p>
                <br/>
    
    <h2>PCB Industry</h2><p></p>            
                
                  <div className="col-md-3 col-sm-6 col-xs-6 hero-feature">
                      <div className="thumbnail prod-block">
                      <img width="250" height="150" src={`${process.env.PUBLIC_URL}/uploads/2019/04/ventec.jpg`} className="attachment-medium size-medium wp-post-image" alt="" loading="lazy" />                   
            <h3>	<a href="http://www.venteclaminates.com/" className="product-title" target="_blank" rel="bookmark" title="Permanent Link to Ventec">
                Ventec</a></h3>
              <div className="pro-dis"> <p>Ventec – Copper Clad Laminates</p>
    </div>
                          </div>
                      </div>
                        
                
                  <div className="col-md-3 col-sm-6 col-xs-6 hero-feature">
                      <div className="thumbnail prod-block">
                      <img width="250" height="150" src={`${process.env.PUBLIC_URL}/uploads/2019/04/thin-flex.jpg`} className="attachment-medium size-medium wp-post-image" alt="" loading="lazy"/>                  
            <h3>	<a href="http://www.thinflex.com.tw/" className="product-title" target="_blank" rel="bookmark" title="Permanent Link to Thinflex">
                Thinflex</a></h3>
              <div className="pro-dis"> <p>Flexible CCL, coverlay and Bond Ply</p>
    </div>
                          </div>
                      </div>
                        
                
                  <div className="col-md-3 col-sm-6 col-xs-6 hero-feature">
                      <div className="thumbnail prod-block">
                      <img width="250" height="150" src={`${process.env.PUBLIC_URL}/uploads/2019/04/ccp.jpg`} className="attachment-medium size-medium wp-post-image" alt="" loading="lazy"/>                  
            <h3>	<a href="http://www.ccp.com.tw/" className="product-title" target="_blank" rel="bookmark" title="Permanent Link to CCP">
                CCP</a></h3>
              <div className="pro-dis"> <p>Dryfilm Phot Resist, Copper Foil</p>
    </div>
                          </div>
                      </div>
                        
                
                  <div className="col-md-3 col-sm-6 col-xs-6 hero-feature">
                      <div className="thumbnail prod-block">
                      <img width="291" height="201" src={`${process.env.PUBLIC_URL}/uploads/2019/05/dupont_bg.png`} className="attachment-medium size-medium wp-post-image" alt="" loading="lazy"/>                  
            <h3>	<a href="http://www.dupont.com/products-and-services/electronic-electrical-materials.html" className="product-title" target="_blank" rel="bookmark" title="Permanent Link to Dupont">
                Dupont</a></h3>
              <div className="pro-dis"> <p>Dupont</p>
    </div>
                          </div>
                      </div>
            <br/>
   <h2>Consumables</h2><p></p>            
                
                  <div className="col-md-3 col-sm-6 col-xs-6 hero-feature">
                      <div className="thumbnail prod-block">
                      <img width="300" height="182" src={`${process.env.PUBLIC_URL}/uploads/2014/10/d0a10bae-eb3c-449c-bcbf-9e53824ddd59-300x182.jpg`} className="attachment-medium size-medium wp-post-image" alt="" loading="lazy" srcset="https://lifequoteshub.com/pcbconsumeables/wp-content/uploads/2014/10/d0a10bae-eb3c-449c-bcbf-9e53824ddd59-300x182.jpg 300w, https://lifequoteshub.com/pcbconsumeables/wp-content/uploads/2014/10/d0a10bae-eb3c-449c-bcbf-9e53824ddd59.jpg 897w" sizes="(max-width: 300px) 100vw, 300px"/>                  
            <h3>	<a href="" className="product-title" target="_blank" rel="bookmark" title="Permanent Link to Routing">
                Routing</a></h3>
              <div className="pro-dis"> <p>Base plate for routing machines</p>
    </div>
                          </div>
                      </div>
                        
                
                  <div className="col-md-3 col-sm-6 col-xs-6 hero-feature">
                      <div className="thumbnail prod-block">
                      <img width="300" height="222" src={`${process.env.PUBLIC_URL}/uploads/2014/10/final-image.jpg`} className="attachment-medium size-medium wp-post-image" alt="" loading="lazy"/>                  
            <h3>	<a href="http://pleiger.com/" className="product-title" target="_blank" rel="bookmark" title="Permanent Link to PLEIGER">
                PLEIGER</a></h3>
              <div className="pro-dis"> <p>2.5mm melamine laminated back-up material (HDF)</p>
    </div>
                          </div>
                      </div>
                        
                
                  <div className="col-md-3 col-sm-6 col-xs-6 hero-feature">
                      <div className="thumbnail prod-block">
                      <img width="250" height="150" src={`${process.env.PUBLIC_URL}/uploads/2019/04/tricom.jpg`} className="attachment-medium size-medium wp-post-image" alt="" loading="lazy"/>                  
            <h3>	<a href="https://www.pcbconsumables.com/wp-content/uploads/2019/04/Diazo-Film1.pdf" className="product-title" target="_blank" rel="bookmark" title="Permanent Link to Diazo Film">
                Diazo Film</a></h3>
              <div className="pro-dis"> </div>
                          </div>
                      </div>
                        
                
                  <div className="col-md-3 col-sm-6 col-xs-6 hero-feature">
                      <div className="thumbnail prod-block">
                      <img width="250" height="150" src={`${process.env.PUBLIC_URL}/uploads/2019/04/al.jpg`} className="attachment-medium size-medium wp-post-image" alt="" loading="lazy"/>                  
            <h3>	<a href="https://www.pcbconsumables.com/wp-content/uploads/2019/04/Lubricated-aluminum-introduction.pdf" className="product-title" target="_blank" rel="bookmark" title="Permanent Link to Lubricated aluminum">
                Lubricated aluminum</a></h3>
              <div className="pro-dis"> </div>
                          </div>
                      </div>
                        
                
                  <div className="col-md-3 col-sm-6 col-xs-6 hero-feature">
                      <div className="thumbnail prod-block">
                      <img width="250" height="150" src={`${process.env.PUBLIC_URL}/uploads/2019/04/ventec.jpg`} className="attachment-medium size-medium wp-post-image" alt="" loading="lazy"/>                  
            <h3>	<a href="https://www.pcbconsumables.com/wp-content/uploads/2019/04/HDF.pdf" className="product-title" target="_blank" rel="bookmark" title="Permanent Link to HDF Back up Board">
                HDF Back up Board</a></h3>
              <div className="pro-dis"> </div>
                          </div>
                      </div>
                        
                
                  <div className="col-md-3 col-sm-6 col-xs-6 hero-feature">
                      <div className="thumbnail prod-block">
                      <img width="250" height="150" src={`${process.env.PUBLIC_URL}/uploads/2019/04/pcb.jpg`} className="attachment-medium size-medium wp-post-image" alt="" loading="lazy"/>                  
            <h3>	<a href="https://www.pcbconsumables.com/wp-content/uploads/2019/04/0.5mm-PHP-data-sheet.pdf" className="product-title" target="_blank" rel="bookmark" title="Permanent Link to Phenolic">
                Phenolic</a></h3>
              <div className="pro-dis"> </div>
                          </div>
                      </div>
                        
                
                  <div className="col-md-3 col-sm-6 col-xs-6 hero-feature">
                      <div className="thumbnail prod-block">
                      <img width="250" height="150" src={`${process.env.PUBLIC_URL}/uploads/2019/04/silieet.jpg`} className="attachment-medium size-medium wp-post-image" alt="" loading="lazy"/>                  
            <h3>	<a href="https://www.pcbconsumables.com/wp-content/uploads/2019/04/Cushion-pad.pdf" className="product-title" target="_blank" rel="bookmark" title="Permanent Link to Silicon Cushion pad">
                Silicon Cushion pad</a></h3>
              <div className="pro-dis"> </div>
                          </div>
                      </div>
            </div>
    </div>
    </div>        
    </div>
    
            </div>
    
     <Footer/>
        </div>
  );
};

const products = [
  {
    title: 'Ventec',
    link: 'http://www.venteclaminates.com/',
    image: '/pcbconsumeables/wp-content/uploads/2019/04/ventec.jpg',
    width: 250,
    height: 150,
    alt: '',
    description: 'Ventec – Copper Clad Laminates',
  },
  {
    title: 'Thinflex',
    link: 'http://www.thinflex.com.tw/',
    image: '/pcbconsumeables/wp-content/uploads/2019/04/thin-flex.jpg',
    width: 250,
    height: 150,
    alt: '',
    description: 'Flexible CCL, coverlay and Bond Ply',
  },
  {
    title: 'CCP',
    link: 'http://www.ccp.com.tw/',
    image: '/pcbconsumeables/wp-content/uploads/2019/04/ccp.jpg',
    width: 250,
    height: 150,
    alt: '',
    description: 'Dryfilm Phot Resist, Copper Foil',
  },
  {
    title: 'Dupont',
    link: 'http://www.dupont.com/products-and-services/electronic-electrical-materials.html',
    image: '/pcbconsumeables/wp-content/uploads/2019/05/dupont_bg.png',
    width: 291,
    height: 201,
    alt: '',
    description: 'Dupont',
  },
];

const consumables = [
  {
    title: 'Routing',
    link: '',
    image: '/pcbconsumeables/wp-content/uploads/2014/10/d0a10bae-eb3c-449c-bcbf-9e53824ddd59-300x182.jpg',
    width: 300,
    height: 182,
    alt: '',
    description: 'Base plate for routing machines',
  },
  {
    title: 'PLEIGER',
    link: 'http://pleiger.com/',
    image: '/pcbconsumeables/wp-content/uploads/2014/10/final-image.jpg',
    width: 300,
    height: 222,
    alt: '',
    description: '2.5mm melamine laminated back-up material (HDF)',
  },
  {
    title: 'Diazo Film',
    link: 'https://www.pcbconsumables.com/wp-content/uploads/2019/04/Diazo-Film1.pdf',
    image: '/pcbconsumeables/wp-content/uploads/2019/04/tricom.jpg',
    width: 250,
    height: 150,
    alt: '',
    description: '',
  },
  {
    title: 'Lubricated aluminum',
    link: 'https://www.pcbconsumables.com/wp-content/uploads/2019/04/Lubricated-aluminum-introduction.pdf',
    image: '/pcbconsumeables/wp-content/uploads/2019/04/al.jpg',
    width: 250,
    height: 150,
    alt: '',
    description: '',
  },
];

export default Industry;
