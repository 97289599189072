import React from "react";

const Banner=()=>{
    const styles = {
        position: "relative",
        zIndex: 0, // Use camelCase and an integer
      };
    return (
        <div className="banner" >
        <div id="carousel-example-generic" className="carousel slide"  style={styles} data-ride="carousel">
            
            <ol className="carousel-indicators">
                <li data-target="#carousel-example-generic" data-slide-to="0" className="active"></li>
                <li data-target="#carousel-example-generic" data-slide-to="1"></li>
                <li data-target="#carousel-example-generic" data-slide-to="2"></li>
            </ol>

           
            <div className="carousel-inner">
           			 <div className="item active">   
			  <img src="https://lifequoteshub.com/pcbconsumeables/wp-content/uploads/2014/10/banner-2.jpg" />
			  <div className="carousel-caption">
			   <h2>Over 20 Years of PCB EXPERTISE</h2>
Trust Your PCB prototype project to the only vendor with the experience to deliver quality from quote to delivery			 
			  </div>
			  
			 </div>
						 <div className="item ">   
			  <img src="https://lifequoteshub.com/pcbconsumeables/wp-content/uploads/2014/10/banner-3.jpg"/>
			  <div className="carousel-caption">
			   <h2>Over 20 Years of PCB EXPERTISE</h2>
Trust Your PCB prototype project to the only vendor with the experience to deliver quality from quote to delivery			  
			  </div>
			  
			 </div>
						 <div className="item ">   
			  <img src="https://lifequoteshub.com/pcbconsumeables/wp-content/uploads/2014/10/banner-1.jpg"/>
			  <div className="carousel-caption">
			   <h2>Over 20 Years of PCB EXPERTISE</h2>
Trust Your PCB prototype project to the only vendor with the experience to deliver quality from quote to delivery			  
			  </div>
			  
			 </div>
			            </div>

          
            <a className="left carousel-control" href="#carousel-example-generic" role="button" data-slide="prev">
                <span className="glyphicon glyphicon-chevron-left"></span>
            </a>
            <a className="right carousel-control" href="#carousel-example-generic" role="button" data-slide="next">
                <span className="glyphicon glyphicon-chevron-right"></span>
            </a>
        </div>
    </div>
    );

};
export default Banner;